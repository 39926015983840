import type { AuthUser } from '~/libs/api-client';
import { setUser } from '~/store/user';

/*
 * Makes the user object available on client side
 */
export function UserProvider(props: { user?: AuthUser }) {
  setUser(props.user);
  return null;
}
